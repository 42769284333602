import React from "react";
import { SubSideBar } from "../../components";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import Container from "./Container";

const Messaging = () => {
  const { user } = useSelector((state) => state.user);
  const RootStyle = styled("div")({
    display: "flex",
    height: "100%",
    background: "#fff",
  });

  return (
    <RootStyle>
      <SubSideBar />
      <Container user={user} />
    </RootStyle>
  );
};

export default Messaging;
