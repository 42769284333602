import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ContentHeader } from "../../../components";
import Messages from "./Messages";
import UserDetails from "./UserDetails";
import { styled } from "@mui/material/styles";
import data from "../../../utilities/constants";
import ReplyZone from "./ReplyZone";
import { useLocation } from "react-router-dom";
import {
  getAllConversations,
  getOneConversationByClient,
} from "../../../slices/conversation";
import { conversationMessages } from "../../../slices/messages";
import { createHashHistory } from "history";
import {
  getArchivedConversations,
  getOneArchivedConversation,
} from "../../../slices/archivedConversation";
import { getArchivedMessages } from "../../../slices/archivedMessages";

const Container = ({ user, archived }) => {
  const [active, setActive] = useState(data.ISSUE_DETAILS_LIST[0].id);
  const [todo, setTodo] = useState(null);
  const history = createHashHistory();
  const { state } = useLocation();
  const [activeTodoList, setActiveToDoList] = useState(null);
  const dispatch = useDispatch();
  const MainStyle = styled("div")(({ theme }) => ({
    flexGrow: 1,
    width: "70%",
    paddingTop: data.APP_BAR_MOBILE + 3,
    // [theme.breakpoints.up("lg")]: {
    //   paddingLeft: theme.spacing(2),
    // },
  }));

  useEffect(() => {
    state &&
      (async () => {
        dispatch(
          archived
            ? getArchivedConversations({ websiteID: user?.websiteID, page: 1 })
            : getAllConversations({ websiteID: user?.websiteID, page: 1 })
        );
        dispatch(
          archived
            ? getOneArchivedConversation({
                websiteID: user?.websiteID,
                conversationID: state?.conversation,
              })
            : getOneConversationByClient({
                conversationId: state?.conversation,
                user,
                websiteId: user?.websiteID,
                userId: user?._id,
              })
        );
        dispatch(
          archived
            ? getArchivedMessages({
                websiteID: user?.websiteID,
                conversationID: state?.conversation,
                page: 1,
              })
            : conversationMessages({
                conversationId: state?.conversation,
                websiteId: user?.websiteID,
                page: 1,
              })
        );
        setActiveToDoList(state);
        history.replace("", null);
      })();
  }, [state]);

  useEffect(() => {
    if (todo) setActiveToDoList({ ...activeTodoList, messageID: todo });
  }, [todo]);
  return (
    <React.Fragment>
      {
        <div className="messaging-container">
          <div style={{ width: "60%", flexGrow: 1 }}>
            {!archived && <ContentHeader user={user} />}
            <MainStyle className="messaging-content">
              <Messages
                user={user}
                state={state}
                setTodo={setTodo}
                archived={archived}
              />
              {!archived && <ReplyZone user={user} />}
            </MainStyle>
          </div>
          <UserDetails
            user={user}
            todo={todo}
            state={state}
            setTodo={setTodo}
            active={active}
            setActive={setActive}
            activeToDoList={activeTodoList}
            setActiveToDoList={setActiveToDoList}
            archived={archived}
          />
        </div>
      }
    </React.Fragment>
  );
};
export default Container;
